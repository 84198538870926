import '~/styles'

import * as React from 'react'
import { Provider, Client as UrqlClient } from 'urql'
import Head from '~/components/Head'
import Layout from '~/components/Layout'
import GlobalCSSVars from '~/components/GlobalCSSVars'
import { StoreProvider } from '~/Store'
import routes from './routes'

type Props = {
  urqlClient: UrqlClient,
}

const App = ({ urqlClient }: Props) => (
  <Provider value={urqlClient}>
    <Head />
    <GlobalCSSVars />
    <StoreProvider>
      <Layout>
        {routes}
      </Layout>
    </StoreProvider>
  </Provider>
)

export default App
