import { useQuery, gql } from 'urql'
import type { Book, Page, Homepage, ConfigSettings } from '~/types'

export const QUERY = gql`
  query CoreQuery {
    configsettings {
      metatags {
        id
        title
        description
        keywords
      }
      analytics {
        id
        googleAnalyticsId
      }
    }

    homepage {
      id
      bgImage {
        id
        uid
        url
        width
        height
        title
        altText
        captionText
        images
      }
    }

    books {
      id
      slug
      title
      buyUrl
      image {
        id
        uid
        url
        width
        height
        title
        altText
        captionText
        images
      }
      praise {
        id
        text
      }
      content {
        id
        slug
        title
        text
      }
    }

    pages {
      id
      slug
      title
      inAboutSection
      text
    }
  }
`

type QueryData = {
  homepage: Homepage,
  pages: Page[],
  books: Book[],
  configsettings: ConfigSettings,
}

/**
 * Query all core data from GraphQL api
 */
export const useCoreQuery = () => {
  const [{ fetching, error, data }] = useQuery<QueryData>({ query: QUERY })
  return { error, data, fetching }
}
