import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Client as UrqlClient } from 'urql'
import { HelmetProvider } from 'react-helmet-async'
import App from '../App'


export type Props = {
  urqlClient: UrqlClient,
}

export const ClientApp = ({ urqlClient }: Props) => (
  <BrowserRouter>
    <HelmetProvider>
      <App urqlClient={urqlClient} />
    </HelmetProvider>
  </BrowserRouter>
)

export default ClientApp
