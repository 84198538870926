import { styled } from 'linaria/react'
import { theme } from '~/styles/theme'

export const ContentBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > *:first-child,
  .Markup > *:first-child {
    margin-top: 0;
  }

  > *:last-child,
  .Markup > *:last-child {
    margin-bottom: 0;
  }

  ${theme.mediaQueries.smDown} {
    min-height: var(--window-height, 100vh);
    padding: 3rem;
    padding-top: calc(3rem + var(--spine-height));
  }

  ${theme.mediaQueries.md} {
    min-height: var(--window-height, 100vh);
    padding: 4rem 7rem 4rem 6.8rem;
  }
`

export default ContentBox
