import * as React from 'react'
import { useState, useEffect } from 'react'
import { NavLink as Link, useLocation } from 'react-router-dom'
import { styled } from 'linaria/react'
import cx from 'classnames'
import { theme } from '~/styles/theme'
import { useBooks } from '~/hooks/useBooks'
import { useHomepage } from '~/hooks/useHomepage'
import { useStore } from '~/hooks/useStore'
import type { Book } from '~/types'

export type Props = {
  className?: string,
}

const TRANSITION_DURATION = 550

export const MainMenu = ({ className }: Props) => {
  const { books } = useBooks()
  const { homepage } = useHomepage()

  // Manage global state to track animation
  const [state, dispatch] = useStore()

  // Homepage visibility state (used for triggering hide animation)
  const visibilityClass = state.menu.visibility

  // Hide the menu
  const hideMenu = () => {
    dispatch({ type: 'MAIN_MENU_SET_HIDING' })

    setTimeout(() => {
      dispatch({ type: 'MAIN_MENU_SET_HIDDEN' })
    }, TRANSITION_DURATION)
  }

  // Show the menu
  const showMenu = () => {
    dispatch({ type: 'MAIN_MENU_SET_VISIBLE' })
  }

  // Set visiblity based on whether we're on the homepage
  const location = useLocation()

  useEffect(() => {
    const isHomePage = location.pathname === '/'
    if (!isHomePage && visibilityClass === 'visible') hideMenu()
    if (isHomePage) showMenu()
  }, [location.pathname])

  // Handle preloading book page images
  const preloadBookImage = (book: Book) => {
    if (!book.image) return
    const img = new Image()
    img.sizes = '100vw'
    img.srcset = Object.values(book.image.images).map(im => `${im.url} ${im.width}w`).join(', ')
  }

  useEffect(() => { books?.map(b => preloadBookImage(b)) }, [books])

  // Wait until books are loaded
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (books?.length) {
      setTimeout(() => { setIsLoaded(true) }, 100)
    }
  }, [books])

  const bookCount = books?.length ?? 0

  return (
    <nav
      className={cx(className, visibilityClass, { isLoaded })}
      style={{
        '--transition-duration': `${TRANSITION_DURATION}ms`,
        '--n-links': bookCount + 2,
      } as any}
    >
      <Link
        to="/about"
        aria-label="About Vendela Vida"
        style={{ '--n': 0 } as any}
      ><span>Vendela Vida</span></Link>

      {(books ?? []).map((book, i) => (
        <Link
          to={`/book/${book.slug}`}
          key={book.id}
          aria-label={`Book: ${book.title}`}
          style={{ '--n': i + 1 } as any}
          className={cx({ isLongText: book.title.length > 30 })}
        ><span>{book.title}</span></Link>
      ))}

      <Link
        to="/page/essays-book-reviews"
        style={{ '--n': bookCount + 1 } as any}
      ><span>Essays &amp; Book Reviews</span></Link>

      {homepage?.bgImage && (
        <img
          src={homepage.bgImage.images.large.url}
          srcSet={Object.values(homepage.bgImage.images).map(im => `${im.url} ${im.width}w`).join(', ')}
          sizes="100vw"
          width={homepage.bgImage.width}
          height={homepage.bgImage.height}
          alt={homepage.bgImage.altText || homepage.bgImage.captionText || homepage.bgImage.title || ''}
          className={`${className}__bgImage`}
        />
      )}
    </nav>
  )
}

export default styled(MainMenu)`
  position: fixed;
  z-index: ${theme.zIndices.nav};
  top: 0;
  left: 0;
  width: 100%;
  height: var(--window-height, 100vh);
  overflow: hidden;
  opacity: 0;
  transition-timing-function: ease-out;

  &.isLoaded {
    opacity: 1;
    transition: opacity 550ms;
  }

  &__bgImage {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% + 100vw - 100%);
    object-fit: cover;
    transition-duration: var(--transition-duration);
    transition-property: opacity;
  }

  > a {
    ${theme.typography.navType}
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: calc(var(--n) * var(--spine-height));
    height: var(--spine-height);
    width: 100%;
    padding: 1rem 2rem;
    border: solid 1px transparent;
    border-top-color: white;
    text-align: center;
    text-decoration: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.1);
    white-space: nowrap;

    &:first-of-type {
      border-color: transparent;
    }

    &.active {
      background-color: black;
    }

    @media (pointer: fine) {
      &:hover {
        background-color: black;
      }
    }

    &.active {
      z-index: 2;
      border-color: transparent;
      transition-duration: var(--transition-duration);
      transition-property: none;
      transition-timing-function: inherit;
    }
  }

  /* Hidden state and hiding animation */
  &.hiding,
  &.hidden {
    > a:not(.active) {
      transform: translateY(-100vh);
    }

    > a.active {
      top: 0;
    }
  }

  &.hiding {
    > a.active {
      transition-property: left, top, transform, opacity, font-size;
    }

    > a:not(.active) {
      opacity: 0;
      background-color: transparent;
    }
  }

  &.hiding &__bgImage {
    opacity: 0;
  }

  /* Hidden state */
  &.hidden {
    background: transparent;
    z-index: 0;

    > a:not(.active) {
      display: none;
    }
  }

  &.hidden &__bgImage {
    opacity: 0;
  }

  /* Horizontal */
  ${theme.mediaQueries.md} {
    @supports (writing-mode: vertical-rl) {
      > a {
        position: absolute;
        top: 0;
        left: calc(var(--n) * var(--spine-width));
        width: var(--spine-width);
        height: var(--window-height, 100vh);
        display: flex;
        justify-content: center;
        align-items: center;
        writing-mode: vertical-rl;
        border-left-color: white;
        border-top-color: transparent;

        > * {
          position: relative;
          left: -5px;
          transform: scale(-1);
        }
      }

      /* Hidden state and hiding animation */
      &.hiding,
      &.hidden {
        > a.active {
          left: 0;
        }
      }

      &.hiding > a.isLongText {
        font-size: 4.0vh;
      }

      @media (max-height: 800px) {
        &.isLongText:not(.isHovering) {
          font-size: 3.6vh;
        }
      }
    }
  }
`
