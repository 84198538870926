import { useBooks } from './useBooks'

/**
 * Get a book by slug via graphql query
 */
export const useBook = (slug: string) => {
  const { error, fetching, books } = useBooks()
  const book = books?.find(b => b.slug === slug) ?? null
  return { error, fetching, book }
}
