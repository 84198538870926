import * as React from 'react'
import { createContext, useReducer } from 'react'
import { initialState, Reducer } from '~/Reducer'
import type { State, Action } from '~/Reducer'

/**
 * React context object
 */
export type StoreContext = [State, React.Dispatch<Action>]

export const StoreContext = createContext([
  initialState,
  () => undefined,
] as StoreContext)

/**
 * StoreProvider component for global state
 */
export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, initialState)

  return (
    <StoreContext.Provider value={[state, dispatch]}>
      {children}
    </StoreContext.Provider>
  )
}

export default StoreProvider
