/**
 * Global state object
 */
export const initialState = {
  menu: {
    visibility: 'hidden' as 'visible' | 'hiding' | 'hidden',
  },
}

export type State = typeof initialState

export type ActionType =
  | 'MAIN_MENU_SET_VISIBLE'
  | 'MAIN_MENU_SET_HIDING'
  | 'MAIN_MENU_SET_HIDDEN'

export type Action = {
  type: ActionType,
  payload?: any,
}

/**
 * Reducer function for manipulating global state.
 */
export const Reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'MAIN_MENU_SET_VISIBLE':
      return state.menu.visibility === 'visible'
        ? state : ({
          ...state,
          menu: { ...state.menu, visibility: 'visible' },
        })
    case 'MAIN_MENU_SET_HIDING':
      return state.menu.visibility === 'hiding'
        ? state : ({
          ...state,
          menu: { ...state.menu, visibility: 'hiding' },
        })
    case 'MAIN_MENU_SET_HIDDEN':
      return state.menu.visibility === 'hidden'
        ? state : ({
          ...state,
          menu: { ...state.menu, visibility: 'hidden' },
        })
    default:
      return state
  }
}
