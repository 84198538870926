import * as React from 'react'
import { styled } from 'linaria/react'

export type Props = {
  className?: string,
}

export const Home = ({ className }: Props) => {
  return (
    <div className={className} />
  )
}

export default styled(Home)`
  position: relative;
`
