import * as React from 'react'
import { useState, useEffect } from 'react'
import { styled } from 'linaria/react'
import { useLocation } from 'react-router'
import MainMenu from '~/components/MainMenu'
import Footer from '~/components/Footer'
import { mediaQueries, zIndices } from '~/styles/theme'

export type Props = {
  children: React.ReactNode,
  className?: string,
}

export const Layout = ({ children, className }: Props) => {
  const location = useLocation()
  const [showFooter, setShowFooter] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowFooter(location.pathname !== '/')
    }, 20)
  }, [location.pathname])

  return (
    <div className={className}>
      <MainMenu />

      <main className={`${className}__content`}>
        {children}
      </main>

      {showFooter && <Footer />}
    </div>
  )
}

export default styled(Layout)`
  &__content {
    position: relative;
    z-index: ${zIndices.content};

    ${mediaQueries.md} {
      padding-left: var(--spine-width);
    }
  }
`
