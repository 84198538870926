import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useConfigSettings } from '~/hooks/useConfigSettings'

export const Head = () => {
  const { configsettings } = useConfigSettings()
  if (!configsettings) return null

  const { metatags } = configsettings
  const siteTitle = metatags.title ?? 'Vendela Vida'

  return (
    <Helmet titleTemplate={`%s | ${siteTitle}`} defaultTitle={siteTitle}>
      <html lang="en" />
      <meta name="description" content={metatags.description ?? ''} />
      <meta name="keywords" content={metatags.keywords ?? ''} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:title" content={siteTitle} />
      <link rel="icon" href="data:;base64,iVBORw0KGgo=" />
      <link rel="stylesheet" href="https://use.typekit.net/lbc3ogl.css" />
    </Helmet>
  )
}

export default Head
