import useLayoutEffect from '@react-hook/passive-layout-effect'
import { useViewport } from '~/hooks/ui/useViewport'
import { useBooks } from '~/hooks/useBooks'

/**
 * Sets custom css variable to the viewport height
 *
 * Because of how many mobile browsers handle the url bar, simply using 100vh
 * does not work as expected. This sets a custom css variable to the current
  * window height so it can be used in css as so:
  *
  * height: var(--windowHeight, 100vh);
  *
  * or using vh :
  *
  * height: calc(var(--vh, 1vh) * 100);
 */

const setCssVar = (name: string, value: string | number) => {
  document.documentElement.style.setProperty(
    `--${name}`,
    `${value}${typeof value === 'number' ? 'px' : ''}`,
  )
}

export const GlobalCSSVars = () => {
  const viewport = useViewport()
  const { books } = useBooks()

  useLayoutEffect(() => {
    setCssVar('vh', viewport.height * 0.01)
    setCssVar('window-height', viewport.height)
    if (books?.length) {
      setCssVar('spine-height', viewport.height / (books.length + 2))
      setCssVar('spine-width', viewport.width / (books.length + 2))
    }
  }, [viewport.height, viewport.width, books])

  return null
}

export default GlobalCSSVars
