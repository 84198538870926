import scroll from 'scroll'

/**
 * Returns the top-level scrollable element
 */
export function getScrollingElement () {
  return (document.scrollingElement || document.documentElement) as HTMLElement
}

export const easeInOut = (n: number) => (
  n < 0.5
    ? ((n * 2) ** 2) / 2
    : 1 - (((1 - n) * 2) ** 2) / 2
)

export const DEFAULT_EASE = easeInOut

export type ScrollOptions = {
  duration?: number,
  offset?: number,
  ease?: (time: number) => number,
  onEnd?: () => void,
  cancelable?: boolean,
}

/**
 * Scroll page to the given position
 */
export function scrollTo (y: number, opt: ScrollOptions = {}) {
  // handle defaults
  const duration = opt?.duration
  const offset = opt?.offset ?? 0
  const ease = opt?.ease ?? DEFAULT_EASE
  const onEnd = opt?.onEnd

  // find scrollable element
  const scrollEl = getScrollingElement()

  // do the scroll
  const cancel = scroll.top(scrollEl, y + offset, {
    duration,
    ease,
  }, () => {
    if (onEnd) onEnd()

    // clean up event listeners
    if (opt.cancelable) {
      window.removeEventListener('wheel', cancel)
      window.removeEventListener('touchstart', cancel)
      window.removeEventListener('mousedown', cancel)
    }
  })

  // cancel scroll on user interaction
  if (opt.cancelable) {
    window.addEventListener('wheel', cancel)
    window.addEventListener('touchstart', cancel)
    window.addEventListener('mousedown', cancel)
  }
}

/**
 * Scroll to the given element
 */
export function scrollToElement (arg: string | HTMLElement, opt: ScrollOptions = {}) {
  // find DOM node if given as a string
  const el = typeof arg === 'string'
    ? document.querySelector(arg) as HTMLElement
    : arg
  if (!el) return

  // find scrollable element
  const scrollEl = getScrollingElement()

  // calculate scroll top position
  const bbox = el.getBoundingClientRect()
  const top = bbox.top + scrollEl.scrollTop

  scrollTo(top, opt)
}


/**
 * Returns true if element has scroll overflow
 */
export const hasOverflow = (el: HTMLElement): boolean => (
  el.scrollHeight > el.offsetHeight || el.scrollWidth > el.offsetWidth
)
