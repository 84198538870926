import * as React from 'react'
import { useRef, useState, useCallback } from 'react'
import cx from 'classnames'
import useResizeObserver from '@react-hook/resize-observer'
import useLayoutEffect from '@react-hook/passive-layout-effect'
import { styled } from 'linaria/react'
import { theme } from '~/styles/theme'
import ContentBox from '~/components/ui/ContentBox'
import { hasOverflow } from '~/utils/dom'

export type Props = React.ComponentProps<typeof ContentBox> & {
  children: React.ReactNode,
  className?: string,
  autoSizeText?: boolean,
}


export const TextSlide = ({ children, className, autoSizeText, ...props }: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  // We only want to show contents after initial sizing
  const [isLoaded, setIsLoaded] = useState(false)

  // Size text dynamically to fit without any overflow
  const sizeText = useCallback(() => {
    if (!ref.current) return
    if (!autoSizeText) return

    // Get element ref
    const el = ref.current

    // Make a clone of element for sizing the text
    const rect = el.getBoundingClientRect()
    const clone = el.cloneNode(true) as HTMLElement
    clone.style.display = 'block'
    clone.style.width = `${rect.width}px`
    clone.style.height = `${rect.height}px`
    clone.style.position = 'fixed'
    clone.style.zIndex = '-1'
    clone.style.opacity = '0'
    clone.style.overflow = 'hidden'

    el.parentElement?.appendChild(clone)

    // Get the current text size
    let textSize = parseFloat(getComputedStyle(el).fontSize)
    const maxTextSize = 250

    // Scale up
    for (let i = 200; i >= 0 && !hasOverflow(clone); --i) {
      textSize += 1
      if (textSize >= maxTextSize) break
      clone.style.fontSize = `${textSize}px`
    }

    // Scale down
    for (let i = 200; i >= 0 && hasOverflow(clone); --i) {
      textSize -= 1
      clone.style.fontSize = `${textSize}px`
    }

    clone.remove()
    el.style.fontSize = `${textSize}px`

    if (!isLoaded) setIsLoaded(true)
  }, [ref, autoSizeText])

  // initial render
  useLayoutEffect(sizeText)

  // on resize
  useResizeObserver(ref, sizeText)

  return (
    <div
      className={cx(className, { isLoaded })}
      {...props}
      ref={ref}
    >
      <ContentBox as="div" className={`${className}__content`}>
        {children}
      </ContentBox>
    </div>
  )
}

export default styled(TextSlide)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  transition: opacity 200ms;

  &:not(.isLoaded) {
    opacity: 0;
  }

  ${theme.mediaQueries.smDown} {
    &__content {
      padding-bottom: 6rem;
    }
  }

  ${theme.mediaQueries.md} {
    &__content {
      padding: 8rem 8rem 11rem 7.8rem;
    }
  }
`
