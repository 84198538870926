import * as React from 'react'
import { styled } from 'linaria/react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { usePage } from '~/hooks/usePage'
import NotFound from '~/components/NotFound'
import Markup from '~/components/ui/Markup'
import PageTitle from '~/components/ui/PageTitle'
import ContentBox from '~/components/ui/ContentBox'

export type Props = {
  className?: string,
}

export const Page = ({ className }: Props) => {
  const params = useParams<{ slug: string }>()
  const { page, fetching } = usePage(params.slug)

  if (!page) return fetching ? null : <NotFound />

  const inAboutSection = /about|contact|news-events/.test(page.slug)
  const pageTitle = inAboutSection ? 'Vendela Vida' : page.title

  return (
    <div className={className}>
      <Helmet title={page.title} />
      <PageTitle to="/" hoverText="Index">{pageTitle}</PageTitle>

      <ContentBox>
        <Markup>{page.text}</Markup>
      </ContentBox>
    </div>
  )
}

export default styled(Page)`
  position: relative;
`
