import * as React from 'react'
import { styled } from 'linaria/react'
import cx from 'classnames'
import { useStore } from '~/hooks/useStore'
import { theme } from '~/styles/theme'

export type Props = {
  className?: string,
}

export const Footer = ({ className }: Props) => {
  const [state] = useStore()
  const isHidden = state.menu.visibility === 'hiding'

  return (
    <footer className={cx(className, { isHidden })}>
      <div className={`${className}__copy`}>
        &copy; 2020 Vendela Vida
      </div>
      <div className={`${className}__credit`}>
        <a
          href="https://www.artdesignoffice.com/"
          target="_blank"
          rel="noopener noreferrer">site by ADO</a>
      </div>
    </footer>
  )
}

export default styled(Footer)`
  ${theme.typography.captionType}
  position: fixed;
  z-index: ${theme.zIndices.nav};
  bottom: 0;
  left: 0;
  width: var(--spine-width);
  height: 3.8rem;
  padding: 0 1.5rem;
  outline: 0;
  border: 0;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;

  &__copy,
  &__credit {
    position: relative;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &:not(:hover) &__credit {
    display: none;
  }

  &:hover &__copy {
    display: none;
  }

  /* underline */
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 1.5rem;
    width: calc(100% - 3rem);
    border-top: solid 1px white;
  }

  /* When hovering on page title, hide last border */
  body.PageTitle--hover &::before {
    display: none;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: inherit;
    text-decoration: none;
  }

  ${theme.mediaQueries.smDown} {
    display: none;
  }

  &.isHidden {
    opacity: 0;
  }

  &:not(.isHidden) {
    opacity: 1;
    transition: opacity 200ms;
  }
`
