/**
 * Colors
 */
const black = '#000'
const white = '#fff'
const gray50 = '#fafafa'
const gray100 = '#f5f5f5'
const gray200 = '#eeeeee'
const gray300 = '#e0e0e0'
const gray400 = '#bdbdbd'
const gray500 = '#9f9f9f'
const gray600 = '#858585'
const gray700 = '#616161'
const gray800 = '#424242'
const gray900 = '#2f2f2f'

const red = '#FF4307'
const green = '#006341'

const primary = red
const text = black
const link = black
const linkHover = black

export const colors = {
  black,
  white,
  gray50,
  gray100,
  gray200,
  gray300,
  gray400,
  gray500,
  gray600,
  gray700,
  gray800,
  gray900,
  red,
  green,
  primary,
  text,
  link,
  linkHover,
}

/**
 * z-indexes
 */
export const zIndices = {
  content: 10,
  nav: 20,
  overlay: 1060,
  modal: 1070,
  top: 1090,
}

/**
 * Space
 */
export const space = {
  paragraphMargin: '1em',
}

/**
 * Breakpoints
 */

const xs = 0
const sm = 768
const md = 1024
const lg = 1228
const xl = 1824
const screen1080p = 1920
const screen2k = 2048
const screen4k = 3840

export const mobile = xs
export const tablet = sm
export const tabletWide = md
export const desktop = lg

export const breakpoints = {
  xs,
  sm,
  md,
  lg,
  xl,
  screen1080p,
  screen2k,
  screen4k,
  mobile,
  tablet,
  tabletWide,
  desktop,
}

/**
 * Media Queries
 */
export const mediaQueries = {
  xs: `@media screen and (max-width: ${breakpoints.sm - 1}px)`,
  sm: `@media screen and (min-width: ${breakpoints.sm}px)`,
  md: `@media screen and (min-width: ${breakpoints.md}px)`,
  lg: `@media screen and (min-width: ${breakpoints.lg}px)`,
  xl: `@media screen and (min-width: ${breakpoints.xl}px)`,
  smDown: `@media screen and (max-width: ${breakpoints.md - 1}px)`,
  mdDown: `@media screen and (max-width: ${breakpoints.lg - 1}px)`,
  lgDown: `@media screen and (max-width: ${breakpoints.xl - 1}px)`,
  smOnly: `@media screen and (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md - 1}px)`,
  mdOnly: `@media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px)`,
  lgOnly: `@media screen and (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xl - 1}px)`,
}

/**
 * Typography
 */

export const sansFontFamily = '"Helvetica Neue", Arial, Helvetica, sans-serif'
export const serifFontFamily = '"Times New Roman", Times, serif'
export const scriptFontFamily = `"Lydia Condensed", ${sansFontFamily}`
export const scriptFontFamily2 = `"freight-text-pro", ${serifFontFamily}`

export const bodyFontFamily = scriptFontFamily2
export const headlineFontFamily = scriptFontFamily

export const lineHeight = 1.34

export const baseType = {
  fontFamily: bodyFontFamily,
  lineHeight,
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
}

export const bodyType = {
  ...baseType,
  fontSize: '3.6rem',

  [mediaQueries.md]: {
    fontSize: '6.4rem',
  },
}

export const smallType = {
  ...baseType,
  fontSize: '1.8rem',

  [mediaQueries.md]: {
    fontSize: '3.2rem',
  },
}

export const headlineType = {
  ...baseType,
  fontFamily: headlineFontFamily,
  fontWeight: 'bold',
  fontSize: '3.6rem',

  [mediaQueries.md]: {
    fontSize: '6.4rem',
  },
}

export const navType = {
  ...baseType,
  fontFamily: headlineFontFamily,
  fontWeight: 'bold',
  fontSize: '5.2vmin',
  lineHeight: 1,

  [mediaQueries.md]: {
    fontSize: '5.5vmin',
  },
}

export const sectionNavType = {
  fontFamily: sansFontFamily,
  fontSize: '1.2rem',
  fontWeight: 500,
  lineHeight: 1.2208,
  textTransform: 'uppercase',
}

export const xSmallType = {
  fontFamily: serifFontFamily,
  fontWeight: 'normal',
  fontSize: '1.4rem',
  lineHeight,
}

export const captionType = {
  fontFamily: sansFontFamily,
  fontWeight: 'normal',
  fontSize: '1.0rem',
  lineHeight,
}

export const typography = {
  scriptFontFamily,
  sansFontFamily,
  serifFontFamily,
  bodyFontFamily,
  headlineFontFamily,
  lineHeight,
  bodyType,
  smallType,
  xSmallType,
  captionType,
  headlineType,
  navType,
  sectionNavType,
}

/**
 * Full theme
 */
export const theme = {
  colors,
  typography,
  zIndices,
  breakpoints,
  mediaQueries,
}

export default theme
