import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import ErrorView from '~/components/ErrorView'

export type Props = {
  location?: RouteComponentProps['location'],
}

export const NotFound = ({ location }: Props) => (
  <ErrorView statusCode={404} title="Not Found">
    <p>Can&apos;t find {location ? <code>{location.pathname}</code> : 'this page'}</p>
  </ErrorView>
)


export default NotFound
