import * as React from 'react'
import { useState, useRef, useLayoutEffect } from 'react'
import { styled } from 'linaria/react'
import PageTitle from '~/components/ui/PageTitle'
import { useViewport } from '~/hooks/ui/useViewport'
import { typography, mediaQueries } from '~/styles/theme'

type TypeClassProps = {
  title: string,
  className?: string,
  children: React.ReactNode,
}

function TypeClass ({ className, title, children }: TypeClassProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [typeAttrs, setTypeAttrs] = useState<{ [k: string]: null | string }>({
    fontSize: null,
    fontFamily: null,
    fontWeight: null,
  })

  const viewport = useViewport()

  useLayoutEffect(() => {
    if (!ref.current) return
    const style = window.getComputedStyle(ref.current)
    const familyMatch = /"?([^"]+)"?/.exec(style.fontFamily.split(',')[0])
    const { fontSize } = style
    const fontFamily = familyMatch ? familyMatch[1] : null
    const fontWeight = (() => {
      switch (style.fontWeight) {
        case '200':
          return 'xLight'
        case '300':
          return 'Light'
        case '400':
          return 'Normal'
        case '700':
          return 'Bold'
        default:
          return style.fontWeight
      }
    })()

    setTypeAttrs({
      fontSize,
      fontFamily,
      fontWeight,
    })
  }, [ref.current, viewport.width])

  return (
    <div>
      <label>
        {title}: &quot;{typeAttrs.fontFamily}, {typeAttrs.fontWeight}, {typeAttrs.fontSize}&quot;
      </label>
      <div className={className} ref={ref}>
        {children}
      </div>
    </div>
  )
}

export type Props = {
  className?: string,
}

export const Typography = ({ className }: Props) => {
  return (
    <>
      <PageTitle to="/">Vendela Vida</PageTitle>
      <div className={className}>
        <TypeClass title="Body" className="body-type">
          <p>
            Vendela Vida is the award-winning author of six books,
            including <a href="#test">Let the Northern Lights Erase Your Name</a> and&nbsp;
            <a href="$test">The Driver&apos;s Clothes Lie Empty</a>
          </p>
        </TypeClass>

        <TypeClass title="Small" className="small-type">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </TypeClass>

        <TypeClass title="Headlines" className="headlineType">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </TypeClass>

        <TypeClass title="Nav links" className="sectionNavType">
          <p>
            Praise | Summary | Reviews
          </p>
        </TypeClass>

        <TypeClass title="Footer / Captions" className="captionType">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et egestas augue.
          </p>
        </TypeClass>
      </div>
    </>
  )
}

export default styled(Typography)`
  padding: 3rem;
  text-align: center;

  > div:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  label {
    font-family: monospace;
    font-size: 1.1rem;
    font-weight: 700;
    padding: 0.4rem;
    border-bottom: dotted black 1px;
  }

  .headlineType {
    ${typography.headlineType}
  }

  .sectionNavType {
    ${typography.sectionNavType}
  }

  .captionType {
    ${typography.captionType}
  }

  ${mediaQueries.smDown} {
    min-height: var(--window-height, 100vh);
    padding-top: calc(3rem + var(--spine-height));
  }
`
