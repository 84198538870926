import { useState, useCallback } from 'react'
import window from 'window-or-global'
import { useEventListener } from '~/hooks/ui/useEventListener'

const getViewport = () => {
  if (!window) return { width: 0, height: 0 }
  const width = window.innerWidth
  const height = window?.visualViewport?.height ?? window.innerHeight
  return { width, height, isTouch: false }
}

export const useViewport = () => {
  const [viewport, setViewport] = useState(getViewport())

  const viewportHandler = useCallback(() => {
    setViewport(vp => {
      const newVp = getViewport()
      const isChanged = (
        newVp.width !== vp.width ||
        newVp.height !== vp.height
      )
      return isChanged ? newVp : vp
    })
  }, [setViewport])

  const touchHandler = useCallback(() => {
    setViewport(vp => {
      if (vp.isTouch) return vp
      return { ...vp, isTouch: true }
    })
  }, [setViewport])

  useEventListener('resize', viewportHandler)
  useEventListener('scroll', viewportHandler)
  useEventListener('touchstart', touchHandler)

  return viewport
}

export default useViewport
