import * as React from 'react'
import { styled } from 'linaria/react'
import { Link } from 'react-router-dom'
import PageTitle from '~/components/ui/PageTitle'
import Status from './Status'
import { typography } from '~/styles/theme'

export type Props = {
  title: string,
  children?: React.ReactNode,
  className?: string,
  debugMessage?: string,
  statusCode?: number,
}

export const ErrorView = ({ title, children, className = '', debugMessage = '', statusCode }: Props) => (
  <div className={className}>
    <PageTitle to="/">Vendela Vida</PageTitle>

    {statusCode ? <Status code={statusCode} /> : null}
    <div className={`${className}__contents`}>
      <h1>{statusCode || ''} {title}</h1>
      {children || null}
      <p><Link to="/">Go Home</Link></p>

      {debugMessage ? (
        <code className={`${className}__debug`}>{debugMessage}</code>
      ) : null}
    </div>
  </div>
)

ErrorView.defaultProps = {
  children: 'oh no! Something went wrong!',
}

export default styled(ErrorView)`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: white;

  &__contents {
    ${typography.xSmallType}
    text-align: center;
  }

  &__debug {
    ${typography.xSmallType}
    display: block;
    margin: 2rem auto 0 auto;
    padding: 1rem;
    max-height: 75vh;
    width: 90vw;
    overflow: auto;
    text-align: left;
    color: red;
    white-space: pre-wrap;
    border: solid 1px #555;
  }
`
