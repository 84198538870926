import * as React from 'react'
import { useState, useEffect } from 'react'
import { styled } from 'linaria/react'
import { Link, LinkProps } from 'react-router-dom'
import cx from 'classnames'
import { theme } from '~/styles/theme'
import { useViewport } from '~/hooks/ui/useViewport'
import { useStore } from '~/hooks/useStore'

export type Props = {
  children: string,
  className?: string,
  style?: React.CSSProperties,
  to?: LinkProps['to'],
  hoverText?: string,
  onClick?: (e: React.MouseEvent) => void,
}

export const PageTitle = ({ children, className, style, to, hoverText, onClick }: Props) => {
  // Hover state
  const [isHovering, setHovering] = useState(false)
  const onMouseEnter = () => setHovering(true)
  const onMouseLeave = () => setHovering(false)

  // Add body class. A bit of a hack, but it allows other components to restyle
  // when hovering on main page title.
  useEffect(() => {
    if (isHovering) {
      document.body.classList.add('PageTitle--hover')
    }
    else {
      document.body.classList.remove('PageTitle--hover')
    }
  }, [isHovering])

  // Check screen size
  const viewport = useViewport()
  const isSmallScreen = viewport.width < theme.breakpoints.md

  // Check text size
  const isLongText = children.length > 30

  // Check if a menu hide animation is in progress
  const [state] = useStore()
  const isMenuHiding = state.menu.visibility === 'hiding'

  return to ? (
    <Link
      to={to}
      className={cx(className, { isLongText, isHovering, isHidden: isMenuHiding })}
      style={style}
      onMouseEnter={isSmallScreen ? undefined : onMouseEnter}
      onMouseLeave={isSmallScreen ? undefined : onMouseLeave}
      onClick={onClick}
    >
      <span>{isHovering ? (hoverText ?? children) : children}</span>
    </Link>
  ) : (
    <h1 className={cx(className, { isHidden: isMenuHiding })} style={style}>
      <span>{children}</span>
    </h1>
  )
}

export default styled(PageTitle)`
  ${theme.typography.navType}
  position: fixed;
  z-index: ${theme.zIndices.nav};
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  background-color: black;
  color: white;
  text-decoration: none;
  white-space: nowrap;

  @media (pointer: fine) {
    &[href]:hover {
      color: white;
      background-color: ${theme.colors.red};
    }
  }

  ${theme.mediaQueries.smDown} {
    width: 100%;
    height: var(--spine-height);
  }

  ${theme.mediaQueries.md} {
    width: var(--spine-width);
    height: var(--window-height, 100vh);
    margin: 0;
    writing-mode: vertical-rl;

    &.isLongText:not(.isHovering) {
      font-size: 4.0vh;
    }

    > span {
      position: relative;
      left: -5px;
      transform: scale(-1);
    }

    @media (max-height: 800px) {
      &.isLongText:not(.isHovering) {
        font-size: 3.6vh;
      }
    }
  }

  &.isHidden {
    opacity: 0;
  }
`
