/* global module */
import * as React from 'react'
import { hydrate } from 'react-dom'
import { configureUrqlClient } from '~/urqlClient'
import ClientApp from './ClientApp'


// urql client
const urqlClient = configureUrqlClient({
  initialState: window.__URQL_STATE__,
})

hydrate(
  <ClientApp urqlClient={urqlClient} />,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept()
}
