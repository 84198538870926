import * as React from 'react'
import { createRef, useState, useEffect, useMemo } from 'react'
import { styled } from 'linaria/react'
import cx from 'classnames'
import { Helmet } from 'react-helmet-async'
import { useAboutPages } from '~/hooks/useAboutPages'
import { useScrollspy } from '~/hooks/ui/useScrollspy'
import { scrollToElement } from '~/utils/dom'
import { useViewport } from '~/hooks/ui/useViewport'
import Markup from '~/components/ui/Markup'
import PageTitle from '~/components/ui/PageTitle'
import NavMenu from '~/components/ui/NavMenu'
import ContentBox from '~/components/ui/ContentBox'
import { theme } from '~/styles/theme'

export type Props = {
  className?: string,
}

export const AboutSection = ({ className }: Props) => {
  const { pages } = useAboutPages()

  // Active section for menu
  const [activePage, setActivePage] = useState<string | undefined>(pages?.[0].slug)

  useEffect(() => {
    if (pages && !activePage) setActivePage(pages?.[0].slug)
  }, [activePage, pages])

  // refs for spy menu items
  const refs = useMemo(() => (
    pages?.map(p => createRef<HTMLElement>()) ?? []
  ), [pages])

  // Scroll spy to track active sections
  useScrollspy(refs, {
    onEnter: (ref) => {
      if (ref.current?.id) {
        setActivePage(ref.current.id)
      }
    },
  })

  // Nav open state
  const [navOpen, setNavOpen] = useState(false)
  const openNav = () => setNavOpen(true)
  const closeNav = () => setNavOpen(false)

  // Screen size
  const viewport = useViewport()
  const isSmallScreen = viewport.width < theme.breakpoints.md

  // Title is clicked
  const handleTitleClick = (e: React.MouseEvent) => {
    if (isSmallScreen) {
      e.preventDefault()
      e.stopPropagation()
      setNavOpen(state => !state)
    }
  }

  // Nav item is clicked
  const handleNavClick = (e: React.MouseEvent, idx: number) => {
    e.preventDefault()
    const ref = refs[idx]
    if (ref.current) {
      setActivePage(ref.current.id)
      scrollToElement(ref.current)
    }
  }

  return (
    <div className={className}>
      <Helmet title="About" />
      <PageTitle to="/" hoverText="Index" onClick={handleTitleClick}>Vendela Vida</PageTitle>

      <NavMenu isOpen={navOpen} onClose={closeNav} onOpen={openNav}>
        {pages?.map((p, i) => (
          <a
            key={p.id}
            href={`#${p.slug}`}
            onClick={e => handleNavClick(e, i)}
            className={cx({ active: activePage === p.slug })}
          >{p.title}</a>
        ))}
      </NavMenu>

      {pages?.map((page, i) => (
        <ContentBox key={page.id} id={page.slug} ref={refs[i]} aria-label={page.title}>
          <Markup>{page.text}</Markup>
        </ContentBox>
      ))}
    </div>
  )
}

export default styled(AboutSection)`
  position: relative;
`
