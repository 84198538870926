import fetch from 'cross-fetch'
import {
  createClient,
  dedupExchange,
  cacheExchange,
  fetchExchange,
  ssrExchange,
  Client,
} from 'urql'
import { GRAPHQL_ENDPOINT } from '~/config'

const isServerSide = typeof window === 'undefined'

export type UrqlClient = Client & {
  ssr: ReturnType<typeof ssrExchange>,
}

/**
 * Create client for urql
 */
export const configureUrqlClient = ({
  url = GRAPHQL_ENDPOINT,
  initialState = {},
} = {}) => {
  const ssr = ssrExchange({
    initialState,
    isClient: !isServerSide,
  })

  const client = createClient({
    url,
    fetch,
    suspense: isServerSide,
    exchanges: [
      dedupExchange,
      cacheExchange,
      ssr,
      fetchExchange,
    ],
  }) as UrqlClient

  client.ssr = ssr

  return client
}

export default configureUrqlClient
