/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react'
import { createRef, useRef, useState, useCallback, useMemo } from 'react'
import { useDrag } from 'react-use-gesture'
import cx from 'classnames'
import { styled } from 'linaria/react'
import { useEventListener } from '~/hooks/ui/useEventListener'
import { theme } from '~/styles/theme'

export type Props = {
  children: JSX.Element | JSX.Element[],
  isActive?: boolean,
  className?: string,
}

export const Carousel = ({ className, isActive = true, ...props }: Props) => {
  const componentRef = useRef<HTMLDivElement>(null)

  const children = React.Children.toArray(props.children) as JSX.Element[]

  // A ref for each child element
  const childRefs = useMemo(() => (
    children.map(child => (
      createRef<HTMLDivElement>()
    ))
  ), [children.length])

  // Track the active slide
  const [activeIdx, setActiveIdx] = useState(0)

  // Indicator click
  const handleIndicatorClick = (i: number) => {
    setActiveIdx(i)
  }

  const clamp = (n: number) => (
    (n + childRefs.length) % childRefs.length
  )

  // Go to next/prev slide
  const go = (direction: 'prev' | 'next') => {
    const amount = direction === 'next' ? 1 : -1
    setActiveIdx(i => clamp(i + amount))
  }

  // Handle keyboard
  const handleKey = useCallback((e: KeyboardEvent) => {
    if (!isActive) return
    if (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) return

    switch (e.key) {
      case 'ArrowRight':
        go('next')
        e.preventDefault()
        break
      case 'ArrowLeft':
        go('prev')
        e.preventDefault()
        break
      default:
        break
    }
  }, [activeIdx, isActive, componentRef, childRefs])

  useEventListener('keydown', handleKey)

  // Cursor state
  const [pointerPosition, setPointerPosition] = useState<'next' | 'prev'>('next')

  // Handle mouse move
  const handleMouesMove = (e: React.MouseEvent) => {
    if (!componentRef.current) return

    const rect = componentRef.current.getBoundingClientRect()
    const pointer = e.clientX
    const position = pointer >= ((rect.width * 0.40) + rect.left) ? 'next' : 'prev'
    setPointerPosition(position)
  }

  // Click handler
  const handleClick = (e: React.MouseEvent) => {
    go(pointerPosition)
  }

  // Swipe handler
  useDrag(state => {
    if (state.event.pointerType === 'mouse') return
    const thresh = 0.1
    const { vxvy: [vx], last } = state
    if (last) {
      if (vx < (thresh * -1)) {
        go('next')
      }
      else if (vx > thresh) {
        go('prev')
      }
    }
  }, {
    axis: 'x',
    lockDirection: true,
    threshold: 10,
    domTarget: componentRef,
    eventOptions: { passive: false },
  })

  return (
    <div
      ref={componentRef}
      className={cx(className, {
        pointerNext: children.length > 1 && pointerPosition === 'next',
        pointerPrev: children.length > 1 && pointerPosition === 'prev',
      })}
      onMouseMove={handleMouesMove}
      role="group"
      aria-roledescription="carousel"
    >
      {children.map((child, i) => (
        <div
          key={child.key}
          ref={childRefs[i]}
          className={cx(`${className}__slide`, {
            active: activeIdx === i,
          })}
          onClick={handleClick}
          aria-roledescription="slide"
          aria-label={`${i + 1} of ${children.length}`}
          aria-current={activeIdx === i}
        >
          {child}
        </div>
      ))}
      {children.length > 1 && (
        <div className={`${className}__indicators`}>
          {children.map((child, i) => (
            <button
              key={child.key}
              onClick={() => { handleIndicatorClick(i) }}
              className={cx(`${className}__indicator`, {
                active: activeIdx === i,
              })}
              aria-label={`Select slide ${i + 1} of ${children.length}`}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default styled(Carousel)`
  position: relative;
  height: var(--window-height, 100vh);
  touch-action: pan-y pinch-zoom;

  &.pointerNext {
    cursor: url(/assets/icons/arrow_forward.svg), e-resize;
  }

  &.pointerPrev {
    cursor: url(/assets/icons/arrow_back.svg), w-resize;
  }

  &__slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    z-index: -1;
    opacity: 0;
    transition: opacity 600ms;

    &.active {
      opacity: 1;
      z-index: 0;
    }
  }

  &__indicators {
    position: absolute;
    z-index: 1;
    cursor: default;
  }

  &__indicator {
    position: relative;
    border: 0;
    appearance: none;
    outline: 0;
    cursor: pointer;
    background: none;

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      background: white;
    }

    &.active::after {
      background: ${theme.colors.red};
    }

    @media (pointer: fine) {
      &:hover::after {
        background: ${theme.colors.red};
      }
    }
  }

  ${theme.mediaQueries.smDown} {
    &__indicators {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }

    &__indicator {
      padding: 0.4rem;

      &::after {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  ${theme.mediaQueries.md} {
    &__indicators {
      bottom: 1.4rem;
      left: 50%;
      transform: translateX(-50%);
    }

    &__indicator {
      padding: 0.4rem;

      &::after {
        width: 1.7rem;
        height: 1.7rem;
      }
    }
  }
`
