import { useEffect, useState, useRef, RefObject } from 'react'
import window from 'window-or-global'

const SUPPORTS_OBSERVER = 'IntersectionObserver' in window

/**
 * A hook to spy on elements
 * It takes a list of domRefs and returns the active dom element
 */
export const useScrollspy = (
  domRefs: RefObject<HTMLElement>[],
  opt?: {
    root?: RefObject<HTMLElement>,
    onEnter?: (ref: RefObject<HTMLElement>, idx: number) => void,
  },
): RefObject<HTMLElement> | undefined => {
  // The visible items found by IntersectionObserver
  const visibleItems = useRef<Map<number, IntersectionObserverEntry>>(new Map())

  // The active DOM ref index
  const [activeIndex, setActiveIndex] = useState<number>(-1)

  // The observer tracks when items enter/leave the visible viewport
  const observerRef = useRef<IntersectionObserver>()

  // Initialize the observer
  const handleObserve = () => {
    if (!SUPPORTS_OBSERVER) return

    setActiveIndex(-1)
    visibleItems.current = new Map()

    // Observer
    const observer = observerRef.current = new IntersectionObserver(entries => {
      // Find visible entries
      entries.forEach(e => {
        const idx = domRefs.findIndex(r => r.current === e.target)
        if (e.isIntersecting) visibleItems.current.set(idx, e)
        else visibleItems.current.delete(idx)
      })

      // Find the active entry from the visible ones
      const activeItem = [...visibleItems.current.entries()]
        .map(([idx, entry]) => {
          const rootHeight = entry.rootBounds?.height ?? window.innerHeight
          const percentVisible = Math.round(entry.intersectionRatio * 10) * 10
          const percentOfRoot = Math.round(entry.intersectionRect.height / rootHeight * 10) * 10

          // The weight (higher number means more likely to be the active item)
          // The highest weight is given to items fully on-screen, then to the percent of the screen the item takes up.
          let weight = percentVisible === 100 ? 101 : percentOfRoot

          const isFirstItem = idx === 0
          const isLastItem = idx === domRefs.length - 1
          if (isFirstItem) weight += 2
          if (isLastItem) weight += 1

          return {
            entry,
            idx,
            weight,
            percentOfRoot,
          }
        })
        // Find item with the highest weight
        .sort((a, b) => {
          if (a.weight !== b.weight) return b.weight - a.weight
          if (a.percentOfRoot !== b.percentOfRoot) return b.percentOfRoot - a.percentOfRoot
          return a.entry.boundingClientRect.top - b.entry.boundingClientRect.top
        })[0]

      // Update state if necessary
      if (activeItem) setActiveIndex(activeItem.idx)
    }, {
      root: opt?.root?.current,
      threshold: [...Array(101).keys()].map(n => n / 100),
    })

    // Observe DOM elements
    domRefs.forEach(ref => {
      if (ref.current) observer.observe(ref.current)
    })
  }

  // Tear down observer
  const handleDisconnect = () => {
    if (observerRef.current) observerRef.current.disconnect()
  }

  // Set up observer on mount
  useEffect(() => {
    handleObserve()
    return handleDisconnect
  }, [domRefs, opt?.root])

  // Handle onEnter callback
  useEffect(() => {
    if (opt?.onEnter) {
      const ref = domRefs[activeIndex]
      if (ref) opt.onEnter(ref, activeIndex)
    }
  }, [activeIndex, domRefs])

  return domRefs[activeIndex]
}
