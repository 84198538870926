import * as React from 'react'
import { useRef, useState, useEffect } from 'react'
import { styled } from 'linaria/react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { theme } from '~/styles/theme'
import { useEventListener } from '~/hooks/ui/useEventListener'
import { useLocationChange } from '~/hooks/ui/useLocationChange'
import { useViewport } from '~/hooks/ui/useViewport'
import { useStore } from '~/hooks/useStore'
import ExpandLess from '~/components/icons/ExpandLess.svg'
import ExpandMore from '~/components/icons/ExpandMore.svg'

export type Props = {
  isOpen: boolean,
  onClose: () => void,
  onOpen: () => void,
  children: React.ReactNode,
  className?: string,
}

export const NavMenu = ({ isOpen, onClose, onOpen, children, className }: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  // Close on click
  useEventListener('click', () => {
    if (isOpen) onClose()
  })

  // Close on scroll
  useEventListener('scroll', onClose)

  // Close on location change
  useLocationChange(onClose)

  // For conditional render depending on mobile size screen or not
  const viewport = useViewport()
  const [isMobile, setIsMobile] = useState(true)
  useEffect(() => {
    setIsMobile(viewport.width < theme.breakpoints.md)
  }, [viewport.width])

  // The menu should be hidden during animations.
  // We check the menu state here and hide if necessary.
  const [state] = useStore()
  const isHidden = state.menu.visibility === 'hiding'

  return (
    <div className={cx(className, { isOpen, isHidden })} ref={ref}>
      <nav className={`${className}__nav`}>
        {children}
        {isMobile && <Link to="/" className={`${className}__home-link`}>Index</Link>}
      </nav>
      {isMobile && (
        <button className={`${className}__button`} onClick={onOpen}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </button>
      )}
    </div>
  )
}

export default styled(NavMenu)`
  position: fixed;
  z-index: ${theme.zIndices.nav};
  top: 0;
  left: 0;
  width: var(--spine-width);

  a {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    appearance: none;
    padding: 1.2rem;
    border: 0;
    outline: 0;
    background-color: black;
    color: white;
    cursor: pointer;
    text-decoration: none;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 1.5rem;
      width: calc(100% - 3rem);
      border-top: solid 1px white;
    }

    &:last-child:not(.active):not(:hover) {
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 1.5rem;
        width: calc(100% - 3rem);
        border-bottom: solid 1px white;
      }
    }

    &.active,
    &:hover {
      background-color: ${theme.colors.red};

      &::before,
      & + a::before {
        display: none;
      }
    }
  }

  &__button {
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;

    path {
      fill: white;
    }
  }

  /* Desktop */
  ${theme.mediaQueries.md} {
    a {
      ${theme.typography.sectionNavType}
      position: relative;

      &:first-child::before {
        display: none !important;
      }
    }
  }

  /* Mobile */
  ${theme.mediaQueries.smDown} {
    width: 100%;
    top: var(--spine-height);

    a {
      ${theme.typography.navType}
      height: var(--spine-height);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:last-child::after {
        display: none !important;
      }
    }

    /* Menu state */
    &:not(.isOpen) &__nav {
      a:not(.active) {
        display: none;
      }

      a.active:not(:hover) {
        background-color: black;

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 1.5rem;
          width: calc(100% - 3rem);
          border-bottom: solid 1px white;
        }
      }
    }

    &:not(.isOpen) a {
      display: none !important;
    }

    &__button {
      position: fixed;
      right: 1.7rem;
      top: calc(var(--spine-height) / 2);
      transform: translateY(-50%);
    }
  }

  /* When hovering on page title, hide last border */
  body.PageTitle--hover & a:last-child::after,
  body.PageTitle--hover &:not(.isOpen) a::after {
    display: none !important;
  }

  /* While animating */
  &.isHidden {
    opacity: 0;
  }

  &:not(.isHidden) {
    opacity: 1;
    transition: opacity 200ms;
  }
`
