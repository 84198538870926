import { usePages } from './usePages'

/**
 * Get a page by slug via graphql query
 */
export const usePage = (slug: string) => {
  const { error, fetching, pages } = usePages()
  const page = pages?.find(p => p.slug === slug) ?? null
  return { error, fetching, page }
}
