import { useMemo } from 'react'
import { usePages } from './usePages'

export const useAboutPages = () => {
  const { error, fetching, pages } = usePages()

  const aboutPages = useMemo(() => (
    pages?.filter(p => p.inAboutSection)
  ), [pages])

  return { error, fetching, pages: aboutPages }
}
