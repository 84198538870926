import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useLocationChange = (fn: () => void) => {
  const location = useLocation()
  const [lastLocation, setLastLocation] = useState<typeof location | null>(null)
  useEffect(() => {
    if (lastLocation && lastLocation !== location) fn()
    setLastLocation(location)
  }, [location])
}
