import * as React from 'react'
import { createRef, useState, useEffect, useMemo } from 'react'
import { styled } from 'linaria/react'
import cx from 'classnames'
import { Helmet } from 'react-helmet-async'
import { useParams, useLocation } from 'react-router-dom'
import { useBook } from '~/hooks/useBook'
import { useScrollspy } from '~/hooks/ui/useScrollspy'
import { scrollToElement } from '~/utils/dom'
import { useViewport } from '~/hooks/ui/useViewport'
import { useStore } from '~/hooks/useStore'
import Markup from '~/components/ui/Markup'
import PageTitle from '~/components/ui/PageTitle'
import NavMenu from '~/components/ui/NavMenu'
import TextSlide from '~/components/ui/TextSlide'
import Carousel from '~/components/ui/Carousel'
import ContentBox from '~/components/ui/ContentBox'
import { theme } from '~/styles/theme'

export type Props = {
  className?: string,
}

export const BookDetail = ({ className }: Props) => {
  // Get book
  const location = useLocation()
  const params = useParams<{ slug: string }>()
  const { book } = useBook(params.slug)

  // Active section for menu
  const [activeSection, setActiveSection] = useState('praise')

  // refs for spy menu items
  const refs = useMemo(() => (
    [createRef<HTMLElement>()].concat(book?.content.map(c => (
      createRef<HTMLElement>()
    )) ?? [])
  ), [book])

  // Scroll spy to track active sections
  useScrollspy(refs, {
    onEnter: (ref) => {
      if (ref.current?.id) {
        setActiveSection(ref.current.id)
      }
    },
  })

  // Nav open state
  const [navOpen, setNavOpen] = useState(false)
  const openNav = () => setNavOpen(true)
  const closeNav = () => setNavOpen(false)

  // Set active section if location.hash changes
  useEffect(() => {
    const id = location.hash.replace('#', '')
    if (id) setActiveSection(id)
  }, [location.hash])

  // Screen size
  const viewport = useViewport()
  const isSmallScreen = viewport.width < theme.breakpoints.md

  // Title is clicked
  const handleTitleClick = (e: React.MouseEvent) => {
    if (isSmallScreen) {
      e.preventDefault()
      e.stopPropagation()
      setNavOpen(state => !state)
    }
  }

  // Nav item is clicked
  const handleNavClick = (e: React.MouseEvent, idx: number) => {
    e.preventDefault()
    const ref = refs[idx]
    if (ref.current) {
      setActiveSection(ref.current.id)
      scrollToElement(ref.current)
    }
  }

  // Animation state
  const [state] = useStore()
  const isMenuAnimating = state.menu.visibility === 'hiding'

  // We delay to allow for the animation to start
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    setTimeout(() => { setIsLoaded(true) }, 50)
  }, [])

  // The page is ready when both loaded and not animating. This way we can
  // delay showing some UI elements until the animation has finished.
  const isReady = isLoaded && !isMenuAnimating

  return book ? (
    <div className={cx(className, { isReady, noImage: !book.image })}>
      <Helmet title={book.title} />
      <PageTitle to="/" hoverText="Index" onClick={handleTitleClick}>{book.title}</PageTitle>

      <NavMenu isOpen={navOpen} onClose={closeNav} onOpen={openNav}>
        {book.praise.length > 0 && (
          <a
            href="#praise"
            className={cx({ active: activeSection === 'praise' })}
            onClick={e => handleNavClick(e, 0)}
          >Praise</a>
        )}
        {book.content.map((c, i) => (
          <a
            key={c.id}
            href={`#${c.slug}`}
            className={cx({ active: activeSection === c.slug })}
            onClick={e => handleNavClick(e, i + 1)}
          >{c.title}</a>
        ))}
        {book.buyUrl && (
          <a href={book.buyUrl} target="blank" rel="noopener noreferrer">Buy</a>
        )}
      </NavMenu>

      {!!book.image && (
        <figure className={`${className}__bgImage`}>
          <img
            src={book.image.images.large.url}
            srcSet={Object.values(book.image.images).map(im => `${im.url} ${im.width}w`).join(', ')}
            sizes="100vw"
            width={book.image.width}
            height={book.image.height}
            alt={book.image.altText || book.image.captionText || book.image.title || ''}
          />
        </figure>
      )}

      <section id="praise" ref={refs[0]} aria-label="Praise">
        <Carousel isActive={activeSection === 'praise'}>
          {book.praise.map(p => (
            <TextSlide key={p.id} autoSizeText={isReady}>
              <Markup>{p.text}</Markup>
            </TextSlide>
          ))}
        </Carousel>
      </section>

      {book.content.map((c, i) => (
        <ContentBox key={c.id} id={c.slug} ref={refs[i + 1]} aria-label={c.title}>
          <Markup>{c.text}</Markup>
        </ContentBox>
      ))}
    </div>
  ) : null
}

export default styled(BookDetail)`
  position: relative;
  min-height: var(--window-height, 100vh);
  color: white;

  &.noImage {
    color: black;
  }

  &__bgImage {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--window-height, 100vh);
    padding: 0;
    margin: 0;
    background-color: black;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.5;
    }
  }

  > section {
    opacity: 1;
    transition: opacity 200ms;
  }

  &:not(.isReady) > section {
    opacity: 0;
    transition: none;
  }
`
