import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import NotFound from '~/components/NotFound'
import Typography from '~/components/Typography'
import BookDetail from '~/components/books/BookDetail'
import Page from '~/components/pages/Page'
import AboutSection from '~/components/pages/AboutSection'
import Home from '~/Home'

export default (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/typography" exact component={Typography} />
    <Route path="/book/:slug" exact component={BookDetail} />
    <Route path="/page/:slug" exact component={Page} />
    <Route path="/about" exact component={AboutSection} />
    <Route component={NotFound} />
  </Switch>
)
