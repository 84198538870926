import * as React from 'react'
import { useRef, useCallback, useEffect } from 'react'
import cx from 'classnames'
import window from 'window-or-global'
import { useHistory } from 'react-router'

export type Props = {
  children?: string | null,
  className?: string,
}

export const Markup = React.memo(({ children, className }: Props) => {
  const history = useHistory()
  const ref = useRef<HTMLDivElement>(null)

  // Link handler
  const handleAnchorClick = useCallback((e: MouseEvent) => {
    e.preventDefault()
    const anchor = e.currentTarget as HTMLAnchorElement
    history.push(anchor.pathname)
  }, [])

  // Attach link handlers
  useEffect(() => {
    Array.from(ref.current?.querySelectorAll('a') ?? [])
      .filter(a => (
        a.pathname && a.host === window.location.host
      ))
      .forEach(a => {
        a.removeEventListener('click', handleAnchorClick)
        a.addEventListener('click', handleAnchorClick)
      })
  }, [children])

  return (
    <div
      className={cx('Markup', className)}
      ref={ref}
      dangerouslySetInnerHTML={{ __html: children ?? '' }}
    />
  )
})

export default Markup
